import React, { useState, useEffect } from 'react';
import { useAccount, WagmiConfig, createClient } from 'wagmi';
import { ConnectButton, RainbowKitProvider, getDefaultWallets, darkTheme } from '@rainbow-me/rainbowkit';
import merge from 'lodash.merge';
import {  chain, configureChains } from 'wagmi';
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import "@rainbow-me/rainbowkit/styles.css";
import './App.css';
import { ethers } from 'ethers';
import ABI from './ABI.json'
import mintPng from './mintPng.png'
import logo from './logo.png'
import pinball from './pinball.png'



const { chains, provider } = configureChains(
  [chain.mainnet],
  [alchemyProvider({ alchemyId: process.env.REACT_APP_ALCHEMY_ID }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

function WalletInfo() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [success, setSuccess] = useState()
  const [isMintClicked, setIsMintClicked] = useState(false);

  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 7) {
      setMintAmount(mintAmount + 1);
    }
  };

  const contractAddress = '0x05813bf86debeaE531864271bae01faa5095b0c0';

  const mintNFT = async () => {
    try {
      const contractAddress = '0x05813bf86debeaE531864271bae01faa5095b0c0'; 
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
      const signer = await account.connector.getSigner();
      if (!signer) {
        alert('Unable to get signer from the connected wallet.');
        return;
      }
      const contract = new ethers.Contract(contractAddress, ABI, signer);
  
  
      // transaction overrides
      const transactionOverrides = {
        gasLimit: ethers.utils.hexlify(100000), // 1 million gas limit, adjust as necessary
      };
  
      let transaction;
  
      
      const priceInWei = ethers.utils.parseEther("0.01");
      const totalValue = priceInWei.mul(ethers.BigNumber.from(mintAmount));
      transaction = await contract.mint(mintAmount, {
        ...transactionOverrides,
        value: totalValue, // Adding the value to the transaction overrides
      });  
        
  
      await transaction.wait();
      setSuccess(true)
    } catch (error) {
      console.error('An error occurred!', error);
  }
  };

  useEffect(() => {
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);

  return (
    <header className="">
   
  </header>
  );
}

function Mint() {
  const account = useAccount();
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [web3, setWeb3] = useState(null);
  const [contract, setContract] = useState(null);
  const [mintAmount, setMintAmount] = useState(1); // Initial mint amount
  const [success, setSuccess] = useState()
  const [isMintClicked, setIsMintClicked] = useState(false);
  const [supply, setSupply] = useState()

  const handleDecrement = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };

  const handleIncrement = () => {
    if (mintAmount < 7) {
      setMintAmount(mintAmount + 1);
    }
  };

  

  const contractAddress = '0x05813bf86debeaE531864271bae01faa5095b0c0';


  useEffect(() => {
    const fetchSupply = async () => {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(contractAddress, ABI, provider);
      try {
        const supply = await contract.supply();
        // If your totalSupply() returns a BigNumber, you should convert it to a string
        setSupply(supply.toString());
      } catch (error) {
        console.error("Error fetching supply:", error);
      }
    };
  
    fetchSupply();
  }, []);

  const mintNFT = async () => {
    
    try {
      const contractAddress = '0x05813bf86debeaE531864271bae01faa5095b0c0'; 
      if (!account || !account.connector) {
        alert('Please connect your wallet first.');
        return;
      }
      const signer = await account.connector.getSigner();
      if (!signer) {
        alert('Unable to get signer from the connected wallet.');
        return;
      }
      const contract = new ethers.Contract(contractAddress, ABI, signer);
  
  
      
      // transaction overrides
      const transactionOverrides = {
        gasLimit: ethers.utils.hexlify(150000), // 1 million gas limit, adjust as necessary
      };
  
      let transaction;
  
      
      const priceInWei = ethers.utils.parseEther("0.01");
      const totalValue = priceInWei.mul(ethers.BigNumber.from(mintAmount));
      transaction = await contract.mint(mintAmount, {
        ...transactionOverrides,
        value: totalValue, // Adding the value to the transaction overrides
      });  
        
  
      await transaction.wait();
      setSuccess(true)
    } catch (error) {
      console.error('An error occurred!', error);
  }
  };

  useEffect(() => {
    if (account.address) {
      setIsWalletConnected(true);
    }
  }, [account.address]);

  return (
    <div className='app'>
      < div>
      <div className="mintPng-container">
        <img src={mintPng} alt='mintPng' className='mintPng' />
        <div className="supply-info">{supply}</div>
      </div>
          
      </div>
      <h1 className="title">OH GOD OH MAN OH JEEZ OH MAN
public: 0.01 eth || MAX PER MINT: 7</h1>
            <h2 className="subtitle">9261 unique skulls, some rarer than others, all equally useless. mint will stay open until every last one of these ridiculous things are lost to the internet. grab a sandwich, we're here for the long haul.
</h2>
      {isWalletConnected ? 
      success ? 
        <h2 className="subtitle" style={{fontStyle:'normal', marginTop:'5px'}}>NFT Minted Successfully</h2> :
        <div style={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
          <h2 className='subtitle2'>{mintAmount}</h2>
          <div><button className="decrement-button" onClick={handleDecrement}>-</button>
            <button 
              className="mint-button" 
              onClick={mintNFT} 
              disabled={!isWalletConnected || isMintClicked}
            >
              Mint
            </button>
          <button className="increment-button" onClick={handleIncrement}>+</button> </div>
        </div>
      :<button 
        className="mint-button" 
        onClick={mintNFT} 
        disabled={!isWalletConnected || isMintClicked}
      >
        Mint
      </button>}</div>
);
}

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider theme={darkTheme({
      accentColor: '#feffe3',
      accentColorForeground: 'black',
      borderRadius: 'small',
      fontStack: 'system',
      overlayBlur: 'small',
    })}  chains={chains}>
      <div class="app">
      <header className="app-header">
        <img src={logo} alt="Logo" className="logo" />
        <div className='header-menu'> 
        <nav className="header-nav">
          <a href="#mint">Mint</a>
          <a href="https://www.nousenfts.xyz/#road-map">Roadmap</a>
          <a href="https://www.nousenfts.xyz/#specs">Specs</a>
          <a href="https://www.nousenfts.xyz/burn">Burn</a>
        </nav>
        <a href="https://www.nousenfts.xyz/the-whizzurd/" rel="noopener noreferrer">
    <img src={pinball} alt="Right Side" className="right-side-image" />
  </a>
        <ConnectButton className="header-button" style={{ backgroundColor: 'yellow!' }} />
        
        </div>
      </header>
      
      <WalletInfo />
          <main className="main-content">
          
            <Mint/>
          </main>
          <div className='footer'>
            <h2 className="subtitle">@2023 OFF HVND LLC.</h2>
        </div>
        </div>
       
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
